import React from "react"

export const useMediaQuery = query => {
  let initial

  if (typeof window !== "undefined") {
    const initialQuery = window.matchMedia(query)
    initial = initialQuery.matches
  } else {
    initial = false
  }

  const [match, setMatch] = React.useState(initial)

  React.useEffect(() => {
    const mediaQuery =
      typeof window.navigator === "undefined" ? "" : window.matchMedia(query)
    const handler = () => setMatch(!!mediaQuery.matches)
    mediaQuery.addListener(handler)
    return () => mediaQuery.removeListener(handler)
  }, [])

  return match
}
