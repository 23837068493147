import React, { useState, useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Navbar, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok
} from "@fortawesome/free-brands-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import { useMediaQuery } from "../hooks/useMediaQuery"
import { createLocalLink } from "../utils"
import { hierarchizeFlatList } from "../utils/hierarchizeFlatList"
import { setIsExternalLink } from "../utils/setIsExternalLink"
import Logo from "../images/etp-logo-full-nofill.inline.svg"
import ExternalLinkIcon from "../images/icon-external-link.inline.svg"
import {
  bgClass,
  mainContentWrapper,
  menuHeadingClass,
  subMenuListClass,
  twoColsClass,
  secondMenuCol,
  subMenuItemClass,
  externalLinkIconClass,
  socialWrapperClass,
  socialLinkClass,
  socialIconClass,
  socialIconXClass,
  orgDetailsClass,
  orgPhoneLink,
  logoClass,
  pifLogoClass,
  policyNavClass
} from "./Footer.module.scss"

const MENU_QUERY = graphql`
  query GET_FOOTER_MENU_ITEMS {
    allWpMenuItem(
      filter: { locations: { in: FOOTER } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...MenuFields
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(MENU_QUERY)
  const hierarchicalData = hierarchizeFlatList(data.allWpMenuItem.nodes)

  const [isMobile, setIsMobile] = useState(false)

  const mobileQuery = useMediaQuery("(max-width: 605px)")

  useEffect(() => {
    if (mobileQuery !== undefined) {
      {
        setIsMobile(mobileQuery)
      }
    }
  }, [mobileQuery])

  return (
    <>
      <footer>
        <Navbar className={`${bgClass} px-3 px-xl-5 text-white`}>
          <div className={mainContentWrapper}>
            <div>
              <h2 className="visually-hidden">Footer Menu</h2>
              <Row className="main-footer-menu-items">
                <>
                  {hierarchicalData.map((menuItem, i) => {
                    if (menuItem.children.length) {
                      let subMenuListStyle = subMenuListClass
                      if (menuItem.children.length > 3 && i !== 2) {
                        subMenuListStyle = `${subMenuListClass} ${twoColsClass}`
                      }
                      return (
                        <Col
                          className={
                            i === 1
                              ? `${secondMenuCol} mx-0 me-sm-3`
                              : "mx-0 me-sm-3"
                          }
                          key={menuItem.id}>
                          {isMobile && i === 2 ? null : (
                            <h3
                              className={menuHeadingClass}
                              dangerouslySetInnerHTML={{
                                __html: menuItem.label
                              }}
                            />
                          )}
                          <ul
                            className={`${subMenuListStyle} mb-1 list-unstyled`}>
                            {menuItem.children.map((child, j) => {
                              const isExternal = setIsExternalLink(child.url)

                              if (i === 2 && j > 2) {
                                return
                              } else if (j > 5) {
                                return
                              } else {
                                return (
                                  <li key={child.id}>
                                    {isExternal ? (
                                      <>
                                        <a
                                          className={subMenuItemClass}
                                          href={child.url}>
                                          {child.label}
                                          <span
                                            className="text-white"
                                            role="img"
                                            aria-label="external link">
                                            <ExternalLinkIcon
                                              className={externalLinkIconClass}
                                            />
                                          </span>
                                        </a>
                                      </>
                                    ) : (
                                      <Link
                                        className={subMenuItemClass}
                                        key={menuItem.id}
                                        to={createLocalLink(child.url)}
                                        dangerouslySetInnerHTML={{
                                          __html: child.label
                                        }}
                                      />
                                    )}
                                  </li>
                                )
                              }
                            })}
                          </ul>
                        </Col>
                      )
                    }
                  })}

                  {isMobile && (
                    <Col className="mx-0">
                      <ul className={`${subMenuListClass} mb-1 list-unstyled`}>
                        {/* TODO: UPDATE LINKS WHEN PAGE IS AVAILABLE)
                      <li>
                        <Link className={subMenuItemClass} to="/">
                          Terms
                        </Link>
                      </li>*/}
                        <li>
                          <Link
                            className={subMenuItemClass}
                            to="/privacy-policy">
                            Privacy
                          </Link>
                        </li>
                      </ul>
                    </Col>
                  )}
                </>
              </Row>
            </div>

            <div>
              <Row className={orgDetailsClass}>
                <Col xs="auto" className="d-flex flex-row flex-md-column">
                  <div>
                    <div className="d-flex">
                      <Logo className={logoClass} />
                      <Link
                        to="/blog/the-ept-is-awarded-the-pif-tick"
                        className="p-0 m-0">
                        <StaticImage
                          src="../images/piffIcon.png"
                          className={`${pifLogoClass} d-lg-flex d-none ms-5`}
                        />
                      </Link>
                    </div>
                    <div className="d-lg-none">
                      <Link
                        to="/blog/the-ept-is-awarded-the-pif-tick"
                        className="p-0 m-0">
                        <StaticImage
                          src="../images/piffIcon.png"
                          className={`${pifLogoClass} my-3`}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="ms-5 ms-md-0 mt-0 mt-md-4">
                    <p className="small">
                      <span>Registered Charity:</span>
                      <br />
                      <span>
                        {process.env.CHARITY_NUMBER} (England & Wales)
                      </span>
                      <br />
                      <span>
                        {process.env.CHARITY_NUMBER_SCOTLAND} (Scotland)
                      </span>
                    </p>
                    <p className="small">
                      {process.env.ORG_NAME}
                      <br />
                      {process.env.ORG_ADDRESS_LINE1}
                      <br />
                      {`${process.env.ORG_ADDRESS_LINE2}, ${process.env.ORG_ADDRESS_LINE3}`}
                    </p>
                    <a
                      className={`${orgPhoneLink} mt-2 mt-lg-0 mb-0 small`}
                      href={`tel:${process.env.ORG_TEL}`}>
                      {process.env.ORG_TEL}
                    </a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={socialWrapperClass}>
                    <a
                      href={process.env.INSTAGRAM_URL}
                      target="_blank"
                      rel="noreferrer">
                      <div className={socialLinkClass}>
                        <FontAwesomeIcon
                          icon={faInstagram}
                          size="lg"
                          className={socialIconClass}
                        />
                      </div>
                    </a>

                    <a
                      href={process.env.LINKEDIN_URL}
                      target="_blank"
                      rel="noreferrer">
                      <div className={socialLinkClass}>
                        <FontAwesomeIcon
                          icon={faLinkedinIn}
                          size="lg"
                          className={socialIconClass}
                        />
                      </div>
                    </a>

                    <a
                      href={process.env.FACEBOOK_URL}
                      target="_blank"
                      rel="noreferrer">
                      <div className={socialLinkClass}>
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          size="lg"
                          className={socialIconClass}
                        />
                      </div>
                    </a>

                    <a
                      href={process.env.TWITTER_URL}
                      target="_blank"
                      rel="noreferrer">
                      <div className={socialLinkClass}>
                        <svg
                          className={socialIconXClass}
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512">
                          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                        </svg>
                      </div>
                    </a>

                    <a
                      href={process.env.TIKTOK_URL}
                      target="_blank"
                      rel="noreferrer">
                      <div className={socialLinkClass}>
                        <FontAwesomeIcon
                          icon={faTiktok}
                          size="lg"
                          className={socialIconClass}
                        />
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Navbar>

        <Navbar
          bg="white"
          className={`${policyNavClass} d-flex justify-content-between py-2 px-3 px-lg-3 px-xl-5 small`}>
          <span>
            © {new Date().getFullYear()} {process.env.ORG_NAME}. Website by{" "}
            <a href="https://www.bravand.com/" target="_blank" rel="noopener">
              {" "}
              Bravand
            </a>
          </span>
          {!isMobile && (
            <span>
              {/* TODO: UPDATE LINKS WHEN PAGE IS AVAILABLE
            <Link to="/">Terms</Link>
            <span className="mx-1">{" | "}</span>
            <Link to="/privacy-policy">Privacy</Link>*/}
            </span>
          )}
        </Navbar>
      </footer>
    </>
  )
}

export default Footer
