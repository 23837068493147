import React, { useEffect, useState } from "react"
import * as styles from "./ReachDeckToolbarButton.module.scss"

const ReachDeckToolbarButton = () => {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(typeof window !== "undefined" && typeof window.ReachDeck === "undefined"){
      const script = document.createElement('script');
      script.src = process.env.REACH_DECK_SCRIPT_SRC;
      script.integrity = process.env.REACH_DECK_SCRIPT_INTEGRITY;
      script.crossOrigin = "anonymous";
      script.addEventListener('load', () => setLoaded(true));
      document.body.appendChild(script);
    }
  }, [])

  useEffect(() => {
    if (!loaded) return;
    console.log('reachdesk loaded');

  }, [loaded])

  return (
    <div className={styles.buttonWrapper}>
      <div id="__ba_launchpad" />
    </div>
  )
}

ReachDeckToolbarButton.propTypes = {}

export default ReachDeckToolbarButton
