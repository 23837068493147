export const pageSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "The Ectopic Pregnancy Trust",
  "url": "https://ectopic.org.uk/ ",
  "logo": "https://wp.ectopic.org.uk/wp-content/uploads/2021/05/logo-white@3x-e1622009001581.png",
  "sameAs": [
    "https://www.instagram.com/ectopicpregnancy/",
    "https://www.linkedin.com/company/the-ectopic-pregnancy-trust",
    "https://www.facebook.com/ectopicpregnancytrust/ ",
    "https://twitter.com/theept/ ",
    "https://www.youtube.com/channel/UCkRIRIhitsqBI2WPj5A_vmQ "
  ]
}


export const makePageSchema = data => {
  return JSON.stringify(data)
 }

export const makeMedicalSchema = (data, headline, isPageType) => {
const { authors, reviewers, mainContentOfPage, lastReview } = data?.medicalSchema || null

let authorsArray, reviewersArray
let lastReviewDate = lastReview ? lastReview : ""
if (authors) {
      authorsArray= authors.map(author => {
          return {
            "@type":"Person",
            "name": author.name,
            "url": author.biographyLink
        }
      })
      if(isPageType) {
        authorsArray = {
          "@type": "Organization",
          "url": "https://ectopic.org.uk/",
          "name": "The Ectopic Pregnancy Trust"
        }
      }
    }
if(reviewers) {
    reviewersArray = reviewers.map(reviewers => (
        {
          "@type": "Person",
          "name": reviewers.name,
          "url": reviewers.biographyLink
        }
    ))

}

  return JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": "MedicalWebPage",
      "about": {
      "@type": "MedicalCondition",
       "name": [
         "ectopic pregnancy",
          "ectopic"
       ]
     },
      "lastReviewed": lastReviewDate,
      "mainContentOfPage": mainContentOfPage?.replace(/['"]+/g, ''),
      "headline": headline,
      "author": authorsArray,
      "reviewedBy": reviewersArray
     }
  )
}


export const makeFAQSchema = data => {

let questions = data.map(q => (
  {
    "@type": "Question",
    "name": q.question,
    "acceptedAnswer": {
      "@type": "Answer",
      "text": q.schemaAnswer
    }
  }
))
return JSON.stringify({"@context": "https://schema.org",
   "@type": "FAQPage",
   "mainEntity": questions
})

}




