// extracted by mini-css-extract-plugin
export var bgClass = "Footer-module--bgClass--kfg2l";
export var mainContentWrapper = "Footer-module--mainContentWrapper--R3PCL";
export var menuHeadingClass = "Footer-module--menuHeadingClass--spINL";
export var secondMenuCol = "Footer-module--secondMenuCol--ek5qN";
export var subMenuListClass = "Footer-module--subMenuListClass--Zfm5U";
export var twoColsClass = "Footer-module--twoColsClass--1mc4m";
export var subMenuItemClass = "Footer-module--subMenuItemClass--bvEjY";
export var externalLinkIconClass = "Footer-module--externalLinkIconClass--U3DIU";
export var orgDetailsClass = "Footer-module--orgDetailsClass--b3LZW";
export var socialWrapperClass = "Footer-module--socialWrapperClass--lVz+T";
export var socialLinkClass = "Footer-module--socialLinkClass--bp9u6";
export var socialIconClass = "Footer-module--socialIconClass--E9ea1";
export var socialIconXClass = "Footer-module--socialIconXClass--keZpk";
export var orgPhoneLink = "Footer-module--orgPhoneLink--FBrq1";
export var logoClass = "Footer-module--logoClass--kzI0N";
export var pifLogoClass = "Footer-module--pifLogoClass--K6ECe";
export var policyNavClass = "Footer-module--policyNavClass--Bw4wG";