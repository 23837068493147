import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"
import PropTypes from "prop-types"
import { createLocalLink } from "../../utils"
import { setIsExternalLink } from "../../utils/setIsExternalLink"
import {
  pillLinkClass,
  buttonClass,
  outlineButtonClass,
  outlineButtonDisabledClass
} from "./PillButton.module.scss"

const LinkButton = ({ link, outline, classes, variant, title }) => {
  const isExternal = setIsExternalLink(link)
  const isMailTo = link.match("mailto:")
  const isTel = link.match("tel:")

  let isFundraisersPage
  let path

  if (typeof window !== "undefined") {
    path = global.location.pathname

    if (path.match("/fundraisers")) {
      isFundraisersPage = true
    }
  }

  if (isExternal || isMailTo || isTel) {
    return (
      <a
        className={pillLinkClass}
        href={link}
        target={isExternal && "_blank"}
        rel="noopener noreferrer">
        <Button
          className={
            outline
              ? `${buttonClass} ${outlineButtonClass} ${classes}`
              : `${buttonClass} ${classes}`
          }
          variant={variant}>
          {title}
        </Button>
      </a>
    )
  } else {
    return (
      <Link
        to={createLocalLink(link)}
        state={isFundraisersPage && { isFundraisersPage: true }}>
        <Button
          className={
            outline
              ? `${buttonClass} ${outlineButtonClass} ${classes}`
              : `${buttonClass} ${classes}`
          }
          variant={variant}>
          {title}
        </Button>
      </Link>
    )
  }
}

const FunctionButton = ({
  classes,
  outline,
  variant,
  onClick,
  isDisabled,
  title
}) => {
  let classList = `${buttonClass} ${classes}`

  if (outline && isDisabled) {
    classList = `${buttonClass} ${outlineButtonClass} ${outlineButtonDisabledClass} ${classes}`
  } else if (outline) {
    classList = `${buttonClass} ${outlineButtonClass} ${classes}`
  }

  return (
    <Button
      className={classList}
      variant={variant}
      onClick={onClick}
      disabled={isDisabled}>
      {title}
    </Button>
  )
}

export const PillButton = ({
  classes,
  variant = "secondary",
  outline = false,
  title,
  onClick,
  link,
  isDisabled = false
}) => {
  return (
    <>
      {link ? (
        <LinkButton
          classes={classes}
          variant={variant}
          outline={outline}
          link={link}
          title={title}
        />
      ) : (
        <FunctionButton
          classes={classes}
          variant={variant}
          outline={outline}
          onClick={onClick}
          isDisabled={isDisabled}
          title={title}
        />
      )}
    </>
  )
}

PillButton.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  variant: PropTypes.string,
  outline: PropTypes.bool
}
