import React from "react"
import { Form, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { searchBtn } from "./TopNavbarSearchBar.module.scss"
import { navigate } from "gatsby"

const TopNavbarSearchBar = () => {

  const goToSearchPage = e => {
    e.preventDefault()
    const form = e.currentTarget
    const query = form.elements.search.value
    // todo: change url to /search
    navigate(`/search?query=${query}`)
  }

  return (
    <>
      <Form
        className="d-flex justify-content-end w-100 position-relative"
        inline
        noValidate
        onSubmit={goToSearchPage}>
        <Form.Control
          type="text"
          placeholder="Search our website"
          name="search"
          className=" w-25"
        />
        <Button className={searchBtn} variant="link" type="submit">
          <FontAwesomeIcon color="#e52e92" icon={faSearch} size="lg" />
        </Button>
      </Form>
    </>
  )
}

export default TopNavbarSearchBar
