export { filterPostsByCategories, prioritisePosts } from "./postsHelper"

export const removeTrailingSlash = str => str.length > 1 && str.endsWith("/") ? str.slice(0, -1) : str

export const createLocalLink = url => {
  if (url === null || url === undefined) {
    return null
  }
  if (`#` === url) {
    return null
  }
  if (url === `${process.env.SITE_URL}/home/`) {
    return "/"
  }
  const urlWithoutSlash = removeTrailingSlash(url)
  return urlWithoutSlash.replace(process.env.SITE_URL, ``)
}

export const validateForm = form => {
  const email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i

  const emailTest = email_regex.test(form.elements.email.value)

  const formCheck = form.checkValidity()

  if (emailTest && formCheck) {
    return true
  } else {
    return false
  }
}

export const textHighlight = (question, searchText) => {
  let begginingText = ""
  let endText = ""
  let startPosition = question.toLowerCase().indexOf(searchText.toLowerCase())

  if (startPosition >= 0 && searchText) {
    let endPosition = startPosition + searchText.length
    if (startPosition > 0) {
      begginingText = question.substring(0, startPosition)
    }
    if (endPosition <= question.length)
      endText = question.substring(endPosition, question.length)
  }
  return begginingText + "<strong>" + searchText + "</strong>" + endText
}

export const titleCase = str => {
  let strLowerCase = str.toLowerCase()
  let wordArr = strLowerCase.split(" ").map( currentValue => {
    if (currentValue[0]) {
    return currentValue[0].toUpperCase() + currentValue.substring(1)
    }
  })

  return wordArr.join(" ")
}

export const trailingPath = (path) => {
  if(!path){
    return path
  }

  return removeTrailingSlash(path)
}
