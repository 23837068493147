// extracted by mini-css-extract-plugin
export var navbarContainerClass = "NavbarContainer-module--navbarContainerClass--mdTne";
export var navbarClass = "NavbarContainer-module--navbarClass--RbWvZ";
export var mobileNavbarClass = "NavbarContainer-module--mobileNavbarClass--yz0ym";
export var hasBannerClass = "NavbarContainer-module--hasBannerClass--v++Hb";
export var stickyNavbarClass = "NavbarContainer-module--stickyNavbarClass--pFUIw";
export var mobileLogoClass = "NavbarContainer-module--mobileLogoClass--Rx0rd";
export var navLinkClass = "NavbarContainer-module--navLinkClass--0VSFm";
export var navDropdownClass = "NavbarContainer-module--navDropdownClass--eBy7W";
export var activeDropdownClass = "NavbarContainer-module--activeDropdownClass--EIDyX";
export var dropdownItemClass = "NavbarContainer-module--dropdownItemClass--shTJL";
export var externalLinkIconClass = "NavbarContainer-module--externalLinkIconClass--2XlZa";
export var mobileExternalLinkIconClass = "NavbarContainer-module--mobileExternalLinkIconClass--Y-YFM";
export var donateButtonClass = "NavbarContainer-module--donateButtonClass--NAcWr";
export var modalClass = "NavbarContainer-module--modalClass--UButd";
export var closeMenuIcon = "NavbarContainer-module--closeMenuIcon--DwEdO";
export var hamburgerIcon = "NavbarContainer-module--hamburgerIcon--4iy7m";