import React, { useState, useEffect } from "react"
import Navbar from "react-bootstrap/Navbar"
import { Link } from "gatsby"
import DesktopMenu from "./DesktopMenu"
import MobileMenu from "./MobileMenu"
import { useScrollPosition } from "../../hooks/useScrollPosition"
import DesktopLogo from "../../images/etp-logo-full-white.inline.svg"
import MobileLogo from "../../images/etp-logo-small-white.inline.svg"
import {
  navbarContainerClass,
  stickyNavbarClass,
  navbarClass,
  hasBannerClass,
  brandClass,
  mobileLogoClass,
  mobileNavbarClass
} from "./NavbarContainer.module.scss"
import TopNavbarSearchBar from "./TopNavbarSearchBar"

const NavbarContainer = ({ location }) => {
  const [hideOnScroll, setHideOnScroll] = useState(false)
  const [mobileToggle, setMobileToggle] = useState(false)
  const [hasBanner, setHasBanner] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y && currPos.y < -80
      if (isShow !== hideOnScroll) {
        setHideOnScroll(!hideOnScroll)
      }
    },
    [hideOnScroll],
    false,
    false,
    200
  )

  useEffect(() => {
    const banner = document.getElementById("banner")
    banner && setHasBanner(true)
  }, [])

  const renderNavbar = (fixed = false) => {

    const desktopNavBarContainerClasses = `${navbarContainerClass} ${fixed && stickyNavbarClass}`
    const desktopNavBarClasses = `${navbarClass} ${hasBanner && hasBannerClass}`

    //const desktopNavBarClasses = `${navbarClass} ${fixed && stickyNavbarClass} ${hasBanner && hasBannerClass}`
    const mobileNavBarClasses = `mobile-navbar ${mobileNavbarClass} ${navbarClass} ${fixed && stickyNavbarClass}`

    return (
      <>
        <Navbar
          className={`${mobileNavBarClasses} px-3 py-2 d-lg-none`}
          expand="lg">
          <Link className={brandClass} to="/">
            <span role="img" aria-label={`${process.env.ORG_NAME} Logo`}>
              <MobileLogo className={mobileLogoClass} />
            </span>
          </Link>
          <MobileMenu
            location={location}
            toggle={[mobileToggle, setMobileToggle]}
          />
        </Navbar>
        <div className={desktopNavBarContainerClasses}>
          <Navbar
            bg="secondary"
            className={`d-none d-lg-flex py-lg-3 px-lg-3 px-xl-5`}
            expand="lg">
            <TopNavbarSearchBar />
          </Navbar>
          <Navbar
            className={`${desktopNavBarClasses} d-none d-lg-flex d-lg-block py-lg-5 px-lg-3 px-xl-5`}
            expand="lg">
            <Link className={brandClass} to="/">
              <span role="img" aria-label={`${process.env.ORG_NAME} Logo`}>
                <DesktopLogo />
              </span>
            </Link>
            <DesktopMenu
              location={location}
              toggle={[mobileToggle, setMobileToggle]}
            />
          </Navbar>
        </div>
      </>
    )
  }

  return <>{renderNavbar(hideOnScroll, location)}</>
}

NavbarContainer.defaultProps = {}

export default NavbarContainer
