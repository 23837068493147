export const setIsExternalLink = url => {
  const regex = /http.?:\/\/(?:w{3}\.)?(.*?)(?=\/)/
  const linkMatch = url.match(regex)
  if (linkMatch && !process.env.SITE_URL.match(linkMatch[1])) {
    return true
  } else if (linkMatch && url.match(/\/talk\/?$/)) {
    return true
  } else {
    return false
  }
}
