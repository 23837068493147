import React from "react"
import Helmet from "react-helmet"
import Navbar from "./Navbar/NavbarContainer"
import Footer from "./Footer"

import CustomJavascript from "./CustomJavascript"
import StickyHelp from "./UI/StickyHelp"
import ReachDeckToolbarButton from "./UI/ReachDeckToolbarButton"
import unescape from "lodash/unescape"
import parseHTML from "html-react-parser"
import { makePageSchema } from "../utils/makePageSchema"
import { pageSchema } from "../utils/makePageSchema"
const TemplateWrapper = ({
  children,
  seo,
  paginationComponent,
  defaultMenuBackgroundColour,
  location
}) => {
  const searchMeta = {}

  const path = location?.pathname ?? "/"
  const isSearchPage = path.match("/search")

  if (isSearchPage) {
    const term = decodeURI(location.search.replace("?query=", ""))
    Object.assign(searchMeta, {
      title: `Search - ${term}`,
      description: `Search results for ‘${term}’ on The Ectopic Pregnancy Trust`
    })
  }

  const { title, metaDesc, opengraphImage } = {...seo, ...searchMeta}

  return (
    <div className="h-100">
      <Helmet encodeSpecialCharacters={true} title={title}>
        {metaDesc && <meta name="description" content={metaDesc} />}
        {opengraphImage && (
          <meta property="og:image" content={opengraphImage.sourceUrl} />
        )}
        {opengraphImage && (
          <meta
            property="og:image:width"
            content={opengraphImage.mediaDetails.width}
          />
        )}
        {opengraphImage && (
          <meta
            property="og:image:height"
            content={opengraphImage.mediaDetails.height}
          />
        )}
        {paginationComponent && paginationComponent.nextLink && <link rel="next" href={`${process.env.SITE_URL}${paginationComponent.nextLink}`}/>}
        {paginationComponent && paginationComponent.prevLink && <link rel="prev" href={`${process.env.SITE_URL}${paginationComponent.prevLink}`}/>}
        {/*{paginationComponent && paginationComponent.currentLink && <link rel="canonical" href={`${process.env.SITE_URL}${paginationComponent.currentLink}`}/>}*/}
        <script type="application/ld+json">{parseHTML(makePageSchema(pageSchema))}</script>
      </Helmet>
      <Navbar
        defaultMenuBackgroundColour={defaultMenuBackgroundColour}
        location={location}
      />
      <div>{children}</div>
      <Footer />
      <ReachDeckToolbarButton />
      <StickyHelp />
      <CustomJavascript />
    </div>
  )
}

export default TemplateWrapper
