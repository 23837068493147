import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { PillButton } from "../UI/PillButton"
import { createLocalLink } from "../../utils"
import { hierarchizeFlatList } from "../../utils/hierarchizeFlatList"
import { setIsExternalLink } from "../../utils/setIsExternalLink"
import ExternalLinkIcon from "../../images/icon-external-link.inline.svg"
import {
  navLinkClass,
  navDropdownClass,
  dropdownItemClass,
  activeDropdownClass,
  externalLinkIconClass,
  donateButtonClass
} from "./NavbarContainer.module.scss"

const MENU_QUERY = graphql`
  query GET_MENU_ITEMS {
    allWpMenuItem(
      filter: { locations: { in: PRIMARY } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...MenuFields
      }
    }
  }
`

const renderNavLink = menuItem => {
  return (
    <Link
      key={menuItem.id}
      to={createLocalLink(menuItem.url)}
      className={navLinkClass}
      dangerouslySetInnerHTML={{ __html: menuItem.label }}
    />
  )
}


const renderDropdownItem = menuItem => {
  const isExternal = setIsExternalLink(menuItem.url)
  return (
    <>
      {isExternal ? (
        <a className={dropdownItemClass} href={menuItem.url} target="_blank" rel="noopener noreferrer" key={menuItem.id}>
          {menuItem.label}
          <span role="img" aria-label="external link">
            <ExternalLinkIcon className={`${externalLinkIconClass} ms-3`} />
          </span>
        </a>
      ) : (
        <Link className={dropdownItemClass} to={createLocalLink(menuItem.url)} key={menuItem.id}>
          {menuItem.label}
        </Link>
      )}
    </>
  )
}

const RenderDropdown = menuItem => {

  return (
    <NavDropdown
      className={`${navDropdownClass} ${activeDropdownClass} active`}

      id={menuItem.id}
      title={menuItem.label}
      key={menuItem.id}
      show
      >
      {menuItem.children.map(item => renderDropdownItem(item))}
    </NavDropdown>
  )
}


const DesktopMenu = () => {
  const data = useStaticQuery(MENU_QUERY)
  const hierarchicalData = hierarchizeFlatList(data.allWpMenuItem.nodes)

  return (
    <>
      <Navbar.Collapse className="flex-grow-0" id="basic-navbar-nav">
        {hierarchicalData.map(menuItem => {
          if (menuItem.label !== "Donate Now") {
            return (
              <Nav className="d-flex justify-content-between align-items-center">
                {menuItem.children.length
                  ? RenderDropdown(menuItem)
                  : renderNavLink(menuItem)}
              </Nav>
            )
          } else {
            return (
              <Nav className={`d-none ms-3 d-lg-inline-flex ms-xxl-4`}>
                <PillButton
                  classes={donateButtonClass}
                  variant="outline-light"
                  title={menuItem.label}
                  link={menuItem.url}
                />
              </Nav>
            )
          }
        })}
      </Navbar.Collapse>
    </>
  )
}

export default DesktopMenu
