import React, { useState } from "react"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { CSSTransition } from "react-transition-group"
import { createLocalLink } from "../../utils"
import { hierarchizeFlatList } from "../../utils/hierarchizeFlatList"
import { setIsExternalLink } from "../../utils/setIsExternalLink"
import ExternalLinkIcon from "../../images/icon-external-link.inline.svg"
import ArrowIcon from "../../images/icon-chevron.inline.svg"
import HamburgerIcon from "../../images/icon.hamburger.inline.svg"
import CloseMenuIcon from "../../images/icon-mobile-icon-menuclose.inline.svg"
import {
  mobileExternalLinkIconClass,
  closeMenuIcon,
  hamburgerIcon,
  donateButtonClass
} from "./NavbarContainer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { PillButton } from "../UI/PillButton"

const MENU_QUERY = graphql`
  query GET_MOBIL_MENU_ITEMS {
    allWpMenuItem(
      filter: { locations: { in: PRIMARY } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...MenuFields
      }
    }
  }
`

const NavItemToggle = ({ toggle, children, menuItems }) => {
  const [mobileToggle, setMobileToggle] = useState(!toggle)

  const toggleMenu = e => {
    e.preventDefault()
    setMobileToggle(!mobileToggle)
  }

  const goToSearchPage = e => {
    e.preventDefault()
    // todo: change url to /search
    navigate(`/search`)
  }

  const getDonateMenuItem = () => {

    if(menuItems.length === 0) {
      return null
    }

    const button = menuItems.map(menuItem => {
      if (menuItem.label === "Donate Now") {
        return (
          <PillButton
            classes={`${donateButtonClass} btn-sm me-3`}
            variant="outline-light"
            title={menuItem.label}
            link={menuItem.url}
            key={menuItem.id}
          />
        )
      }
      return null
    })

    return button
  }


  return (
    <div className="nav-item">
      { getDonateMenuItem() }
      <span
        className="me-4"
        role="button"
        aria-label="Search"
        onClick={goToSearchPage}>
        <FontAwesomeIcon color="white" icon={faSearch} size="2x" />
      </span>
      <span
        role="button"
        tabIndex="0"
        aria-label="Toggle nav menu"
        onClick={e => toggleMenu(e)}>
        {mobileToggle ? (
          <CloseMenuIcon className={closeMenuIcon} />
        ) : (
          <HamburgerIcon className={hamburgerIcon} />
        )}
      </span>
      {mobileToggle && children}
    </div>
  )
}

const MobileMenuOpen = ({ menuItems }) => {
  const [menuLevel, setMenuLevel] = useState(1)
  const [activeMenu, setActiveMenu] = useState()
  const [parentMenu, setParentMenu] = useState()

  const EnterMenu = () => {}

  const getNestedItems = item => {
    return (
      <>
        {item.children.map(child => {
          const { id, label, url } = child
          const isExternal = setIsExternalLink(url)
          return (
            <p className="menu-item link custom-menu-item-block" key={id}>
              {isExternal ? (
                <a
                  className="menu-item link"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer">
                  {label}
                  <ExternalLinkIcon
                    className={`${mobileExternalLinkIconClass} ms-3`}
                  />
                </a>
              ) : (
                <Link className="menu-item link" to={createLocalLink(url)}>
                  {label}
                </Link>
              )}
            </p>
          )
        })}
      </>
    )
  }

  const handleNextClick = item => {
    setMenuLevel(menuLevel + 1)
    setParentMenu(activeMenu)
    setActiveMenu(item)
    getNestedItems(item)
  }

  const handlePrevClick = () => {
    setMenuLevel(menuLevel - 1)
    setActiveMenu(parentMenu)
  }

  /*
   *  MobileMenuOpen return
   *
   */

  return (
    <div className="sidebar">
      <CSSTransition
        in={menuLevel === 1}
        timeout={500}
        classNames="menu-1"
        unmountOnExit
        onEnter={EnterMenu}>
        <div className="h-100">
          <div className="menu">
            {menuItems.map(item => {
              if (item.children.length) {
                return (
                  <>
                    <div
                      role="button"
                      aria-label="Go to nested menu items"
                      className="menu-item"
                      onClick={() => {
                        handleNextClick(item)
                      }}>
                      <span>{item.label}</span>
                      <span className="icon-button">
                        <ArrowIcon />
                      </span>
                    </div>
                  </>
                )
              } else {
                const isExternal = setIsExternalLink(item.url)
                return (
                  <>
                    {isExternal ? (
                      <a
                        className="menu-item link"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={item.id}>
                        {item.label}
                      </a>
                    ) : (
                      <Link
                        className="menu-item link"
                        to={createLocalLink(item.url)}
                        key={item.id}>
                        {item.label}
                      </Link>
                    )}
                  </>
                )
              }
            })}
          </div>
        </div>
      </CSSTransition>

      {/* Nested menu items */}

      {menuLevel > 1 && (
        <CSSTransition
          in={menuLevel}
          timeout={500}
          classNames={`menu-${menuLevel}`}
          unmountOnExit
          onEnter={EnterMenu}>
          <div>
            <div className="custom-menu">
              <div className="open-menu-controls nested">
                <span
                  onClick={() => handlePrevClick()}
                  role="button"
                  aria-label="Go to previous menu item">
                  <ArrowIcon className="icon-button icon-left" />
                  <span className="back-to-prev-category-text">
                    {activeMenu.label}
                  </span>
                </span>
              </div>
              {getNestedItems(activeMenu)}
            </div>
          </div>
        </CSSTransition>
      )}
    </div>
  )
}

/*
 *  MOBILE MENU
 *
 */

const MobileMenu = ({ toggle }) => {
  const data = useStaticQuery(MENU_QUERY)
  const hierarchicalData = hierarchizeFlatList(data.allWpMenuItem.nodes)

  return (
    <>
      <NavItemToggle toggle={toggle} menuItems={hierarchicalData}>
        <MobileMenuOpen menuItems={hierarchicalData} toggle={toggle} />
      </NavItemToggle>
    </>
  )
}

export default MobileMenu
