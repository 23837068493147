import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-regular-svg-icons"
import GetHelp from "../GetHelp/index"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { ModalPortal, ToggleModal } from "../../portals/Modal.portal"
import {
  circleContainerClass,
  modalContainer,
  modal,
  line
} from "./StickyHelp.module.scss"

const StickyHelp = () => {
  return (
    <ToggleModal
      toggle={show => {
        return (
          <a className={circleContainerClass} onClick={show}>
            <FontAwesomeIcon icon={faHeart} size="lg" />
            <p className="mb-0">
              <strong>Get help</strong>
            </p>
          </a>
        )
      }}
      content={hide => {
        return (
          <>
            <ModalPortal
              hide={hide}
              hideCloseButton={true}
              size="lg"
              className={modalContainer}
              dialogClassName={modal}
              heading="Get Help">
              <Fragment>
                <hr className={line} />
                <GetHelp hide={hide} />
              </Fragment>
            </ModalPortal>
          </>
        )
      }}
    />
  )
}
export default StickyHelp
