import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Button, Col, Nav, Navbar, Row } from "react-bootstrap"

import close from "../../images/icon-close.svg"
import pin from "../../images/map-pin.svg"
import phone from "../../images/icon-phone.svg"
import { btnClass, closeNavBarClass, fixedFooterClass } from "./index.module.scss"

const GetHelp = ({ hide }) => {
  return (
    <Fragment>
      <div className="px-3">
        <Row className="mb-5 mt-2">
          <Col>
            <p>
              If you are experiencing
              <Link
                to={"/ectopic-pregnancy-symptoms"}
                className=" ps-2 linkClass">
                symptoms of an ectopic pregnancy
              </Link>
              , it's important that you seek medical assistance immediately.
            </p>

            <Row className="flex-column flex-lg-row pt-3 px-lg-4 px-xl-7 mx-lg-auto justify-content-lg-between">
              <Col>
                <Button
                  className={`mb-3 ${btnClass}`}
                  style={{ height: "60px" }}>
                  <a
                    className="text-white"
                    href="https://www.nhs.uk/Service-Search/other-services/Urgent%20Care/LocationSearch/1824"
                    target="_blank">
                    <img className="pe-3" src={pin} alt={"pin"} />
                    Find your nearest urgent care clinic
                  </a>
                </Button>
                <Button className={` mb-3 ${btnClass}`}>
                  <a
                    className="text-white"
                    href="https://www.nhs.uk/Service-Search/other-services/Accident%20and%20emergency%20services/LocationSearch/428"
                    target="_blank">
                    <img className="pe-3" src={pin} alt={"pin"} />
                    Find your nearest A&E
                  </a>
                </Button>
                <Button className={btnClass}>
                  <a className="text-white" href="tel:111">
                    <img className="pe-3" src={phone} alt={"phone"} />
                    Call the NHS on 111
                  </a>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-5 mt-4">
          <Col>
            <p>
            Our team can support you and below are some ways in which we can help. We cannot provide medical advice and are not an emergency service. Call-back available within UK only.
            </p>

            <Row className="flex-column flex-lg-row pt-3 px-lg-4 px-xl-7 mx-lg-auto justify-content-lg-between">
              <Col>
                <Button
                  className={` mb-3 ${btnClass}`}
                  style={{ height: "60px" }}>
                  <a className="text-white" href="tel:020 7733 2653">
                    Contact our support line on <br />020 7733 2653 and request a callback
                  </a>
                </Button>
                <Button className={btnClass}>
                  <Link className="text-white text-decroration-none" to={"/contact-us"}>
                    Write us a message
                  </Link>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Navbar className={`${closeNavBarClass} d-flex pt-0 pb-3`}>
        <Nav className={`${fixedFooterClass} m-auto`}>
          <Nav.Link onClick={hide}>
            <span className="d-flex align-items-center justify-content-center linkClass">
              <span>Close</span>
              <img src={close} alt={"close-icon"} />
            </span>
          </Nav.Link>
        </Nav>
      </Navbar>
    </Fragment>
  )
}

export default GetHelp
