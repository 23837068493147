import { useRef, useLayoutEffect } from "react"
const isBrowser = typeof window !== `undefined`

const getScrollPosition = ({ element, useWindow }) => {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  return useWindow
    ? {
        x: window.scrollX,
        y: window.scrollY,
        w: viewportWidth,
        h: viewportHeight
      }
    : { x: position.left, y: position.top, w: viewportWidth, h: viewportHeight }
}

export const useScrollPosition = (
  effect,
  deps = [],
  element = false,
  useWindow = false,
  wait = null
) => {
  const position = useRef(getScrollPosition({ useWindow }))
  let throttleTimeout = null
  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    if (!isBrowser) {
      return
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, deps)
}
