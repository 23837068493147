import React from "react"

const CustomJavascript = () => {
  return (
    <>
    </>
  )
}

export default CustomJavascript
